<template>
  <div>
    <Footer1 v-if="config && config.footer.footerType === 'Footer1'"></Footer1>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Footer1 from "@/components/footer/Footer1.vue";
import Config from "@/interfaces/config";

@Options({
  components: { Footer1 },
})
export default class Footer extends Vue {
  private config: Config | false = false;
  async created(): Promise<void> {
    this.config = await this.$store.state.config;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>


import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import ContentTitulo1 from "@/interfaces/titulo1";

@Options({
  props: { contentData: Object as PropType<ContentTitulo1> },
})
export default class Titulo1 extends Vue {
  private contentData!: ContentTitulo1;
  async created(): Promise<void> {
    console.log("Created Titulo1");
    if (this.contentData) {
      this.titleStyle.color = this.contentData.titleColor;
      this.titleStyle.fontSize = this.contentData.fontSizeTitle;
    }
  }

  titleStyle = {
    color: "black",
    fontSize: "40px",
  };
}


import { Options, Vue } from "vue-class-component";
import TagManagerId from "@/components/TagManagerId.vue";
import Header from "@/components/Header.vue";
import Content from "@/components/Content.vue";
import Footer from "@/components/Footer.vue";
import Config from "@/interfaces/config";

@Options({
  components: {
    TagManagerId,
    Header,
    Content,
    Footer,
  },
})
export default class Page extends Vue {
  private config: Config | false = false;
  async created(): Promise<void> {
    await this.$store.dispatch("setConfig");
    this.config = await this.$store.state.config;
  }
}

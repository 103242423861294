<template>
  <div v-bind:style="footerStyle" class="footer">
    <div class="spacer-top" />
    <hr class="top-hr" />
    <h2 v-if="partnersImage && partnersImage.length > 0" class="h2__partners">
      Parcerias
    </h2>
    <div
      v-if="partnersImage && partnersImage.length > 0"
      class="partners_logos"
    >
      <img
        v-for="img in partnersImage"
        :key="img"
        :src="img"
        class="logo logo__partners"
        v-bind:style="logoStyle"
      />
    </div>
    <h2 class="h2__title">{{ footerText }}</h2>
    <a :href="whatsLink">
      <button class="btn__whats">
        <i class="fa fa-whatsapp"></i> {{ footerWhatsText }}
      </button>
    </a>
    <img :src="logoImage" class="logo logo__buttom" v-bind:style="logoStyle" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Config from "@/interfaces/config";

@Options({
  props: {
    msg: String,
  },
})
export default class Footer1 extends Vue {
  private config: Config | false = false;
  private footerText = "";
  private footerWhatsText = "";
  private logoImage = "";
  private partnersImage: Array<string> = [];
  private whatsLink = "";
  async created(): Promise<void> {
    console.log("Created Footer1");
    this.config = await this.$store.state.config;
    if (this.config) {
      this.footerText = this.config.footer.footerText;
      this.footerWhatsText = this.config.footer.footerWhatsText;
      this.logoImage = this.config.geral.logoImage;
      this.footerStyle.background = this.config.footer.footerBackground;
      this.partnersImage = this.config.footer.partnersImage;
      this.whatsLink = this.config.geral.whatsLink;
      this.logoStyle.width = this.config.footer.footerLogoWidth;
      this.logoStyle.height = this.config.footer.footerLogoHeight;
    }
  }

  footerStyle = {
    background: "this.footerBackgroundCollor",
  };
  logoStyle = {
    width: "",
    height: "",
  };
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  height: fit-content;
  background-position-x: center;
}
.btn__whats {
  background: rgba(16, 212, 16, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.43);
  border: 1px solid rgba(112, 112, 112, 0);
  border-radius: 36px;
  opacity: 1;
  color: white;
  margin: 65px 0;
  padding: 12px 28px;
  text-align: center;
  font: normal normal bold 20px/23px Helvetica;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}
h2 {
  width: 55vw;
  margin: 0 auto;
  display: block;
  text-align: center;
  font: normal normal bold 80px/65px Bebas Neue;
  letter-spacing: 4.8px;
  color: #9d9d9d;
  text-transform: uppercase;
  opacity: 1;
  font-size: clamp(2rem, 4vw, 6rem);
  line-height: 1;
}
.top-hr {
  border-radius: 8px;
  opacity: 0.9;
  width: 8vw;
  height: 8px;
  background: #9d9d9d;
  margin: 20px auto 40px;
}
.h2__title {
  padding-top: 6vw;
}
.h2__partners {
  padding: 4vw 0 2vw;
}
.logo {
  margin: 0 auto;
  padding: 2vh 0 6vw;
}
.logo__buttom {
  display: block;
}
.logo__partners > div {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.spacer-top {
  padding-bottom: 8vw;
}
@media (max-width: 578px) {
  h2 {
    font: normal normal bold 30px/35px Bebas Neue;
    width: 90vw;
  }
  .btn__whats {
    font: normal normal bold 15px/10px Helvetica;
  }
  .logo {
    width: 125px !important;
    padding: 2vh 2vh 6vh;
  }
}

@media (max-width: 420px) {
  h2 {
    font: normal normal bold 24px/35px Bebas Neue;
  }
  .btn__whats[data-v-2d710e72] {
    margin: 65px auto;
  }
}
</style>


import { Options, Vue } from "vue-class-component";
import Banner1 from "@/components/header/Banner1.vue";
import Config from "@/interfaces/config";

@Options({
  components: {
    Banner1,
  },
  props: {
    msg: String,
  },
})
export default class Header extends Vue {
  private config: Config | false = false;
  async created(): Promise<void> {
    this.config = await this.$store.state.config;
  }
}


import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import ContentProfissional from "@/interfaces/profissional";

@Options({
  props: { contentData: Object as PropType<ContentProfissional> },
})
export default class Profissional extends Vue {
  private contentData!: ContentProfissional;
  async created(): Promise<void> {
    console.log("Created Profissional");

    if (this.contentData) {
      this.imgStyle.backgroundImage = this.contentData.imgBackground;
      this.backgroundTextStyle.backgroundColor =
        this.contentData.textBackground;
      this.titleStyle.color = this.contentData.titleColor;
      this.titleStyle.fontSize = this.contentData.titleFontSize;
      this.titleStyle.fontFamily = this.contentData.titleFontFamily;
      this.subtitleStyle.color = this.contentData.subtitleColor;
      this.subtitleStyle.fontSize = this.contentData.subtitleFontSize;
      this.subtitleStyle.fontFamily = this.contentData.subtitleFontFamily;
      this.textStyle.color = this.contentData.textColor;
      this.textStyle.fontSize = this.contentData.textFontSize;
      this.textStyle.fontFamily = this.contentData.textFontFamily;
    }
  }

  imgStyle = {
    backgroundImage: "",
  };
  backgroundTextStyle = {
    backgroundColor: "black",
  };
  titleStyle = {
    color: "blue",
    fontSize: "30px",
    fontFamily: "",
  };
  subtitleStyle = {
    color: "blue",
    fontSize: "25px",
    fontFamily: "",
  };
  textStyle = {
    color: "blue",
    fontSize: "20px",
    fontFamily: "",
  };
}

<template>
  <div class="content">
    <div class="section">
      <div class="col-1 image-section" v-bind:style="imgStyle">
        <img src="" />
      </div>
      <div class="col-2 text-section">
        <div v-bind:style="titleStyle" class="title">
          {{ contentData.title }}
        </div>
        <div v-bind:style="textStyle" class="text">
          {{ contentData.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import ContentInstitucional1 from "@/interfaces/institucional1";

@Options({
  props: { contentData: Object as PropType<ContentInstitucional1> },
})
export default class Institucional1 extends Vue {
  private contentData!: ContentInstitucional1;
  async created(): Promise<void> {
    console.log("Created Institucional1");
    if (this.contentData) {
      this.titleStyle.color = this.contentData.titleColor;
      this.titleStyle.fontSize = this.contentData.fontSizeTitle;
      this.titleStyle.fontFamily = this.contentData.fontFamilyTitle;
      this.textStyle.color = this.contentData.textColor;
      this.textStyle.fontSize = this.contentData.fontSizeText;
      this.textStyle.fontFamily = this.contentData.fontFamilyText;
      this.imgStyle.backgroundImage = this.contentData.imgBackground;
    }
  }

  titleStyle = {
    color: "black",
    fontSize: "40px",
    fontFamily: "",
  };
  textStyle = {
    color: "black",
    fontSize: "20px",
    fontFamily: "",
  };
  imgStyle = {
    backgroundImage: "",
  };
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content {
  padding: 40px 0;
}
.section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.col-1 {
  width: 40%;
  margin: 10px 0;
}
.col-2 {
  width: 60%;
  margin: 10px 0;
}
.image-section {
  height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
}
.text-section .title {
  text-align: left;
  font-weight: 500;
  padding: 0px 20px;
  letter-spacing: 5px;
}
.text-section {
  margin: auto;
}
.text-section .text {
  margin: 20px 0;
  text-align: left;
  font-weight: 400;
  padding: 0px 20px 0px 20px;
}
@media only screen and (max-width: 600px) {
  .col-1,
  .col-2 {
    width: 100%;
    text-align: center;
  }
  .text-section .title {
    text-align: center;
    margin: 30px 0;
  }
  .text-section .text {
    text-align: center;
  }
}
</style>

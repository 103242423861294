<template>
  <div class="content">
    <hr class="top-hr" v-bind:style="stylehr" />
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import ContentDivisor from "@/interfaces/divisor";

@Options({
  props: { contentData: Object as PropType<ContentDivisor> },
})
export default class Divisor extends Vue {
  private contentData!: ContentDivisor;
  async created(): Promise<void> {
    console.log("Created Divisor1");
    if (this.contentData) {
      this.stylehr.background = this.contentData.color;
      this.stylehr.margin = this.contentData.margin;
      this.stylehr.border = this.contentData.border;
    }
  }
  stylehr = {
    background: "",
    margin: "",
    border: "",
  };
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.top-hr {
  border-radius: 8px;
  opacity: 0.9;
  width: 8vw;
  height: 8px;
}
</style>

<template>
  <div>
    <div v-if="!isMobile()">
      <desktop>
        <div v-bind:style="slideStyle" class="slide">
          <img
            :src="logoImage"
            class="logo logo__top"
            v-bind:style="logoStyle"
          />
          <h1 class="h1__title">{{ bannerText }}</h1>
          <h2 class="h2__desc">{{ bannerDesc }}</h2>
          <a :href="bannerWhatsLink">
            <button class="btn__whats">
              <i class="fa fa-whatsapp"></i> {{ bannerWhatsText }}
            </button>
          </a>
        </div>
      </desktop>
    </div>

    <div v-else>
      <div v-bind:style="slideStyle" class="slide">
        <mobile>
          <img :src="logoImage" class="logo logo__top" />
          <h1 class="h1__title">{{ bannerText }}</h1>
          <h2 class="h2__desc">{{ bannerDesc }}</h2>
          <a :href="bannerWhatsLink">
            <button class="btn__whats">
              <i class="fa fa-whatsapp"></i> {{ bannerWhatsText }}
            </button>
          </a>
        </mobile>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Config from "@/interfaces/config";

@Options({
  props: {
    msg: String,
  },
})
export default class Banner1 extends Vue {
  private config: Config | false = false;
  private logoImage = "";
  private bannerText = "";
  private bannerDesc = "";
  private bannerWhatsText = "";
  private bannerWhatsLink = "";

  isMobile(): boolean {
    //@todo transformar em função globaç
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
  async created(): Promise<void> {
    console.log("Created Banner1");
    this.config = await this.$store.state.config;
    if (this.config) {
      this.slideStyle.background = this.isMobile()
        ? this.config.header.bannerBackgroundMobile
        : this.config.header.bannerBackground;
      this.logoImage = this.config.geral.logoImage;
      this.bannerText = this.config.header.bannerText;
      this.bannerDesc = this.config.header.bannerDesc;
      this.bannerWhatsText = this.config.header.bannerWhatsText;
      this.bannerWhatsLink = this.config.geral.whatsLink;
      this.logoStyle.width = this.config.header.logoWidth;
      this.logoStyle.height = this.config.header.logoHeight;
    }
  }

  slideStyle = {
    background: "",
  };
  logoStyle = {
    width: "",
    height: "",
  };
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.slide {
  height: auto;
  background-size: cover !important;
}
.btn__whats {
  background: rgba(16, 212, 16, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.43);
  border: 1px solid rgba(112, 112, 112, 0);
  border-radius: 36px;
  opacity: 1;
  color: white;
  margin: 40px auto 180px;
  padding: 12px 28px;
  text-align: center;
  font: normal normal bold 20px/23px Helvetica;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}
.top-hr {
  background: #25d0d6 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 0.5;
  width: 9vw;
  height: 8px;
}
.h1__title {
  display: inline-block;
  margin: 30vh 0 -35px 0;
  font-family: Bebas Neue;
  letter-spacing: 9px;
  font-size: clamp(3.5rem, 7vw, 9rem);
  color: #ffffff;
  text-shadow: 0px 6px 10px #00000033;
}
.h2__desc {
  max-width: 50vw;
  margin: 20px auto;
  font-size: clamp(1.4rem, 2vw, 3rem);
}
.logo {
  margin: 0 auto;
  padding-top: 8vh;
}
.logo__top {
  display: block;
}

@media (max-width: 992px) {
  .h2__desc {
    max-width: 90vw;
  }
}

@media (max-width: 420px) {
  .logo {
    width: 180px !important;
    padding-top: 3vh;
  }
  .h1__title {
    margin: 18vh 0 0px 0;
    letter-spacing: 6px;
  }
  .btn__whats {
    opacity: 1;
    color: white;
    margin: 0px auto 180px;
    padding: 10px 26px;
    text-align: center;
    font: normal normal bold 15px/15px Helvetica;
  }
}
</style>

function isMobile() { throw new Error("Function not implemented."); }

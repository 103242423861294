<template>
  <div class="content" v-bind:style="stylehr"></div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import ContentDivisor from "@/interfaces/divisor";

@Options({
  props: { contentData: Object as PropType<ContentDivisor> },
})
export default class Divisor extends Vue {
  private contentData!: ContentDivisor;
  async created(): Promise<void> {
    console.log("Created Divisor");
    if (this.contentData) {
      this.stylehr.margin = this.contentData.margin;
    }
  }
  stylehr = {
    margin: "",
  };
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>

<template>
  <div>
    <div v-if="config?.content?.length > 0">
      <div v-for="content in config.content" :key="content">
        <divisor1
          v-if="content.contentType === 'Divisor1'"
          :content-data="content.contentData"
        />
        <divisor2
          v-if="content.contentType === 'Divisor2'"
          :content-data="content.contentData"
        />
        <divisor3
          v-if="content.contentType === 'Divisor3'"
          :content-data="content.contentData"
        />
        <institucional1
          v-if="content.contentType === 'Institucional1'"
          :content-data="content.contentData"
        />
        <beneficio1
          v-if="content.contentType === 'Beneficio1'"
          :content-data="content.contentData"
        />
        <titulo1
          v-if="content.contentType === 'Titulo1'"
          :content-data="content.contentData"
        />
        <profissional1
          v-if="content.contentType === 'Profissional1'"
          :content-data="content.contentData"
        />
        <profissional2
          v-if="content.contentType === 'Profissional2'"
          :content-data="content.contentData"
        />
        <cta1
          v-if="content.contentType === 'Cta1'"
          :content-data="content.contentData"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Institucional1 from "@/components/content/Institucional1.vue";
import Beneficio1 from "@/components/content/Beneficio1.vue";
import Titulo1 from "@/components/content/Titulo1.vue";
import Profissional1 from "@/components/content/Profissional1.vue";
import Profissional2 from "@/components/content/Profissional2.vue";
import Config from "@/interfaces/config";
import Divisor1 from "@/components/content/Divisor1.vue";
import Divisor2 from "@/components/content/Divisor2.vue";
import Divisor3 from "@/components/content/Divisor3.vue";
import Cta1 from "@/components/content/Cta1.vue";

@Options({
  components: {
    Institucional1,
    Beneficio1,
    Titulo1,
    Profissional1,
    Profissional2,
    Divisor1,
    Divisor2,
    Divisor3,
    Cta1,
  },
})
export default class Content extends Vue {
  private config: Config | false = false;
  async created(): Promise<void> {
    this.config = await this.$store.state.config;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>


import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import ContentBeneficio1 from "@/interfaces/beneficio1";

@Options({
  props: { contentData: Object as PropType<ContentBeneficio1> },
})
export default class Beneficio1 extends Vue {
  private contentData!: ContentBeneficio1;
  async created(): Promise<void> {
    console.log("Created Beneficio1");
    if (this.contentData) {
      this.backgroundSection.background = this.contentData.backgroundSection;
      this.imgStyle.backgroundImage = this.contentData.imageCircle;
      this.icons.color = this.contentData.iconsFontColor;
      this.icons.fontSize = this.contentData.iconsTextFontSize;
      this.iconsMobile.color = this.contentData.iconsMobileFontColor;
      this.iconsMobile.fontSize = this.contentData.iconsMobileTextFontSize;
      this.icon1.icon = this.contentData.icon1;
      this.icon1.color = this.contentData.icon1Color;
      this.icon2.icon = this.contentData.icon2;
      this.icon2.color = this.contentData.icon2Color;
      this.icon3.icon = this.contentData.icon3;
      this.icon3.color = this.contentData.icon3Color;
      this.icon4.icon = this.contentData.icon4;
      this.icon4.color = this.contentData.icon4Color;
      this.icon5.icon = this.contentData.icon5;
      this.icon5.color = this.contentData.icon5Color;
    }
  }

  backgroundSection = {
    background: "",
  };
  imgStyle = {
    backgroundImage: "",
  };
  icons = {
    color: "",
    fontSize: "",
  };
  iconsMobile = {
    color: "",
    fontSize: "",
  };
  icon1 = {
    icon: "",
    color: "",
  };
  icon2 = {
    icon: "",
    color: "",
  };
  icon3 = {
    icon: "",
    color: "",
  };
  icon4 = {
    icon: "",
    color: "",
  };
  icon5 = {
    icon: "",
    color: "",
  };
}

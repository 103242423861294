
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import ContentInstitucional1 from "@/interfaces/institucional1";

@Options({
  props: { contentData: Object as PropType<ContentInstitucional1> },
})
export default class Institucional1 extends Vue {
  private contentData!: ContentInstitucional1;
  async created(): Promise<void> {
    console.log("Created Institucional1");
    if (this.contentData) {
      this.titleStyle.color = this.contentData.titleColor;
      this.titleStyle.fontSize = this.contentData.fontSizeTitle;
      this.titleStyle.fontFamily = this.contentData.fontFamilyTitle;
      this.textStyle.color = this.contentData.textColor;
      this.textStyle.fontSize = this.contentData.fontSizeText;
      this.textStyle.fontFamily = this.contentData.fontFamilyText;
      this.imgStyle.backgroundImage = this.contentData.imgBackground;
    }
  }

  titleStyle = {
    color: "black",
    fontSize: "40px",
    fontFamily: "",
  };
  textStyle = {
    color: "black",
    fontSize: "20px",
    fontFamily: "",
  };
  imgStyle = {
    backgroundImage: "",
  };
}


import { Options, Vue } from "vue-class-component";
import Config from "@/interfaces/config";

@Options({
  props: {
    msg: String,
  },
})
export default class Footer1 extends Vue {
  private config: Config | false = false;
  private footerText = "";
  private footerWhatsText = "";
  private logoImage = "";
  private partnersImage: Array<string> = [];
  private whatsLink = "";
  async created(): Promise<void> {
    console.log("Created Footer1");
    this.config = await this.$store.state.config;
    if (this.config) {
      this.footerText = this.config.footer.footerText;
      this.footerWhatsText = this.config.footer.footerWhatsText;
      this.logoImage = this.config.geral.logoImage;
      this.footerStyle.background = this.config.footer.footerBackground;
      this.partnersImage = this.config.footer.partnersImage;
      this.whatsLink = this.config.geral.whatsLink;
      this.logoStyle.width = this.config.footer.footerLogoWidth;
      this.logoStyle.height = this.config.footer.footerLogoHeight;
    }
  }

  footerStyle = {
    background: "this.footerBackgroundCollor",
  };
  logoStyle = {
    width: "",
    height: "",
  };
}


import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import ContentCta from "@/interfaces/cta";

@Options({
  props: { contentData: Object as PropType<ContentCta> },
})
export default class Cta extends Vue {
  private contentData!: ContentCta;
  async created(): Promise<void> {
    console.log("Created Cta1");
    if (this.contentData) {
      this.ctaStyle.background = this.contentData.textBackground;
      this.ctaStyle.color = this.contentData.textColor;
      this.ctaStyle.fontSize = this.contentData.textFontSize;
      this.ctaStyle.fontFamily = this.contentData.textFontFamily;

      this.btnCtaStyle.backgroundColor = this.contentData.btnBackgroundColor;
      this.btnCtaStyle.color = this.contentData.btnColor;
      this.btnCtaStyle.fontSize = this.contentData.btnFontSize;
      this.btnCtaStyle.fontFamily = this.contentData.textFontFamily;
    }
  }
  ctaStyle = {
    background: "",
    color: "",
    fontSize: "",
    fontFamily: "",
  };
  btnCtaStyle = {
    backgroundColor: "",
    color: "",
    fontSize: "",
    fontFamily: "",
  };
}


import { Options, Vue } from "vue-class-component";
import Footer1 from "@/components/footer/Footer1.vue";
import Config from "@/interfaces/config";

@Options({
  components: { Footer1 },
})
export default class Footer extends Vue {
  private config: Config | false = false;
  async created(): Promise<void> {
    this.config = await this.$store.state.config;
  }
}

<template>
  <div>
    <div v-if="config">
      <TagManagerId :id="config.geral.tagManagerId" />
      <Header />
      <Content />
      <Footer />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TagManagerId from "@/components/TagManagerId.vue";
import Header from "@/components/Header.vue";
import Content from "@/components/Content.vue";
import Footer from "@/components/Footer.vue";
import Config from "@/interfaces/config";

@Options({
  components: {
    TagManagerId,
    Header,
    Content,
    Footer,
  },
})
export default class Page extends Vue {
  private config: Config | false = false;
  async created(): Promise<void> {
    await this.$store.dispatch("setConfig");
    this.config = await this.$store.state.config;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
</style>

<template>
  <div class="content text-center">
    <div v-bind:style="ctaStyle" class="ctaSection">
      <p class="textCta">{{ contentData.text }}</p>
      <a
        v-bind:href="contentData.urlBtn"
        v-bind:style="btnCtaStyle"
        class="btnSection"
        >{{ contentData.textBtn }}</a
      >
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import ContentCta from "@/interfaces/cta";

@Options({
  props: { contentData: Object as PropType<ContentCta> },
})
export default class Cta extends Vue {
  private contentData!: ContentCta;
  async created(): Promise<void> {
    console.log("Created Cta1");
    if (this.contentData) {
      this.ctaStyle.background = this.contentData.textBackground;
      this.ctaStyle.color = this.contentData.textColor;
      this.ctaStyle.fontSize = this.contentData.textFontSize;
      this.ctaStyle.fontFamily = this.contentData.textFontFamily;

      this.btnCtaStyle.backgroundColor = this.contentData.btnBackgroundColor;
      this.btnCtaStyle.color = this.contentData.btnColor;
      this.btnCtaStyle.fontSize = this.contentData.btnFontSize;
      this.btnCtaStyle.fontFamily = this.contentData.textFontFamily;
    }
  }
  ctaStyle = {
    background: "",
    color: "",
    fontSize: "",
    fontFamily: "",
  };
  btnCtaStyle = {
    backgroundColor: "",
    color: "",
    fontSize: "",
    fontFamily: "",
  };
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.text-center {
  text-align: center;
}
.ctaSection {
  padding: 25px 50px 35px;
}
.textCta {
  width: 90%;
  margin: 2vw auto;
}
.btnSection {
  letter-spacing: 2.4px;
  text-transform: uppercase;
  padding: 12px 28px;
  box-shadow: 3px 2px 4px 1px #747474;
  border: 1px solid rgba(112, 112, 112, 0);
  border-radius: 36px;
  opacity: 1;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

@media only screen and (max-width: 600px) {
  .btnSection {
    text-align: justify !important;
    margin: 5vw auto;
  }
}
</style>

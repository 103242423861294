<template>
  <div class="content" v-bind:style="backgroundSection">
    <div class="section">
      <div class="col-1">
        <div class="imgCircle" v-bind:style="imgStyle" />
      </div>
      <div class="col-2 desktop">
        <div class="icons">
          <span class="icon" v-bind:style="icons" style="margin-left: -35%"
            ><i v-bind:class="icon1.icon" v-bind:style="icon1"></i>
            {{ contentData.benefit1 }}</span
          >
          <span class="icon" v-bind:style="icons" style="margin-left: -15%"
            ><i v-bind:class="icon2.icon" v-bind:style="icon2"></i>
            {{ contentData.benefit2 }}</span
          >
          <span class="icon" v-bind:style="icons"
            ><i v-bind:class="icon3.icon" v-bind:style="icon3"></i>
            {{ contentData.benefit3 }}</span
          >
          <span class="icon" v-bind:style="icons" style=""
            ><i v-bind:class="icon4.icon" v-bind:style="icon4"></i>
            {{ contentData.benefit4 }}</span
          >
          <span class="icon" v-bind:style="icons" style="margin-left: -35%"
            ><i v-bind:class="icon5.icon" v-bind:style="icon5"></i>
            {{ contentData.benefit5 }}</span
          >
        </div>
      </div>
      <div class="col-3 mobile">
        <div class="iconsMobile">
          <span class="iconMobile" v-bind:style="iconsMobile" style="width: 50%"
            ><i v-bind:class="icon1.icon" v-bind:style="icon1"></i><br />
            {{ contentData.benefit1 }}</span
          >
          <span class="iconMobile" v-bind:style="iconsMobile" style="width: 50%"
            ><i v-bind:class="icon2.icon" v-bind:style="icon2"></i><br />
            {{ contentData.benefit2 }}</span
          >
          <span class="iconMobile" v-bind:style="iconsMobile" style="width: 50%"
            ><i v-bind:class="icon3.icon" v-bind:style="icon3"></i><br />
            {{ contentData.benefit3 }}</span
          >
          <span class="iconMobile" v-bind:style="iconsMobile" style="width: 50%"
            ><i v-bind:class="icon4.icon" v-bind:style="icon4"></i><br />
            {{ contentData.benefit4 }}</span
          >
          <span
            class="iconMobile"
            v-bind:style="iconsMobile"
            style="width: 100%"
            ><i v-bind:class="icon5.icon" v-bind:style="icon5"></i><br />
            {{ contentData.benefit5 }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import ContentBeneficio1 from "@/interfaces/beneficio1";

@Options({
  props: { contentData: Object as PropType<ContentBeneficio1> },
})
export default class Beneficio1 extends Vue {
  private contentData!: ContentBeneficio1;
  async created(): Promise<void> {
    console.log("Created Beneficio1");
    if (this.contentData) {
      this.backgroundSection.background = this.contentData.backgroundSection;
      this.imgStyle.backgroundImage = this.contentData.imageCircle;
      this.icons.color = this.contentData.iconsFontColor;
      this.icons.fontSize = this.contentData.iconsTextFontSize;
      this.iconsMobile.color = this.contentData.iconsMobileFontColor;
      this.iconsMobile.fontSize = this.contentData.iconsMobileTextFontSize;
      this.icon1.icon = this.contentData.icon1;
      this.icon1.color = this.contentData.icon1Color;
      this.icon2.icon = this.contentData.icon2;
      this.icon2.color = this.contentData.icon2Color;
      this.icon3.icon = this.contentData.icon3;
      this.icon3.color = this.contentData.icon3Color;
      this.icon4.icon = this.contentData.icon4;
      this.icon4.color = this.contentData.icon4Color;
      this.icon5.icon = this.contentData.icon5;
      this.icon5.color = this.contentData.icon5Color;
    }
  }

  backgroundSection = {
    background: "",
  };
  imgStyle = {
    backgroundImage: "",
  };
  icons = {
    color: "",
    fontSize: "",
  };
  iconsMobile = {
    color: "",
    fontSize: "",
  };
  icon1 = {
    icon: "",
    color: "",
  };
  icon2 = {
    icon: "",
    color: "",
  };
  icon3 = {
    icon: "",
    color: "",
  };
  icon4 = {
    icon: "",
    color: "",
  };
  icon5 = {
    icon: "",
    color: "",
  };
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content {
  padding: 40px 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 55%;
  margin: auto;
}
.col-1 {
  width: 50%;
  margin: auto;
}
.col-2 {
  width: 50%;
  margin: auto;
}
.col-3 {
  width: 50%;
  margin: auto;
}
.mobile {
  display: none;
}
.imgCircle {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
}
.icons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icons span.icon {
  margin: 5% 0;
}
.icons span.icon svg {
  font-size: 50px;
  padding: 0 2rem;
}
.iconsMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.iconsMobile span.iconMobile {
  margin: 5% 0;
}
.iconsMobile span.iconMobile svg {
  font-size: 50px;
  margin: 5% 0;
}
@media only screen and (min-width: 1600px) {
  .section {
    width: 40%;
  }
  .imgCircle {
    width: 100%;
    height: 20vw;
  }
  .text-section .text {
    padding: 0px 50px 0px 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .section {
    width: 80%;
  }
  .imgCircle {
    width: 100%;
    height: 40vw;
  }
}
@media only screen and (max-width: 600px) {
  .section {
    width: 90%;
  }
  .col-1,
  .col-2,
  .col-3 {
    width: 100%;
    text-align: center;
  }
  .icons span.icon {
    margin-left: 0 !important;
  }
  .icons span.icon svg {
    padding: 0 1rem;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .imgCircle {
    width: 100%;
    height: 85vw;
  }
}
@media only screen and (max-width: 350px) {
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
  .imgCircle {
    width: 100%;
    height: 85vw;
  }
  .iconsMobile span.iconMobile svg {
    font-size: 40px;
  }
}
</style>

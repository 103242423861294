
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import ContentDivisor from "@/interfaces/divisor";

@Options({
  props: { contentData: Object as PropType<ContentDivisor> },
})
export default class Divisor extends Vue {
  private contentData!: ContentDivisor;
  async created(): Promise<void> {
    console.log("Created Divisor");
    if (this.contentData) {
      this.stylehr.background = this.contentData.color;
      this.stylehr.margin = this.contentData.margin;
      this.stylehr.border = this.contentData.border;
    }
  }
  stylehr = {
    background: "",
    margin: "",
    border: "",
  };
}

<template>
  <div class="content">
    <div class="section">
      <div class="col-1 image-section" v-bind:style="imgStyle"></div>
      <div class="col-2 text-section" v-bind:style="backgroundTextStyle">
        <div class="title">
          <h2 v-bind:style="titleStyle">{{ contentData.title }}</h2>
          <span v-bind:style="subtitleStyle">{{ contentData.subtitle }}</span>
        </div>
        <div class="text">
          <p v-bind:style="textStyle">{{ contentData.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import ContentProfissional from "@/interfaces/profissional";

@Options({
  props: { contentData: Object as PropType<ContentProfissional> },
})
export default class Profissional extends Vue {
  private contentData!: ContentProfissional;
  async created(): Promise<void> {
    console.log("Created Profissional1");

    if (this.contentData) {
      this.imgStyle.backgroundImage = this.contentData.imgBackground;
      this.backgroundTextStyle.backgroundColor =
        this.contentData.textBackground;
      this.titleStyle.color = this.contentData.titleColor;
      this.titleStyle.fontSize = this.contentData.titleFontSize;
      this.titleStyle.fontFamily = this.contentData.titleFontFamily;
      this.subtitleStyle.color = this.contentData.subtitleColor;
      this.subtitleStyle.fontSize = this.contentData.subtitleFontSize;
      this.subtitleStyle.fontFamily = this.contentData.subtitleFontFamily;
      this.textStyle.color = this.contentData.textColor;
      this.textStyle.fontSize = this.contentData.textFontSize;
      this.textStyle.fontFamily = this.contentData.textFontFamily;
    }
  }

  imgStyle = {
    backgroundImage: "",
  };
  backgroundTextStyle = {
    backgroundColor: "black",
  };
  titleStyle = {
    color: "blue",
    fontSize: "30px",
    fontFamily: "",
  };
  subtitleStyle = {
    color: "blue",
    fontSize: "25px",
    fontFamily: "",
  };
  textStyle = {
    color: "blue",
    fontSize: "20px",
    fontFamily: "",
  };
}
</script>

<style scoped lang="scss">
.section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.col-1 {
  width: 30%;
}
.col-2 {
  width: 70%;
}
.text-section .title,
.text-section .text {
  text-align: left;
  padding: 20px 50px;
}
.text-section .title h2 {
  text-shadow: -3px 3px 4px #757575;
  margin-bottom: 5px;
}
.image-section {
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 1600px) {
  .image-section {
    height: 350px;
  }
}
@media only screen and (max-width: 600px) {
  .col-1,
  .col-2 {
    width: 100%;
  }
  .image-section {
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px;
    background-position: top;
  }
}
</style>

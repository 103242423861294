<template>
  <div></div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Config from "@/interfaces/config";

@Options({
  props: {
    id: "",
  },
})
export default class Header extends Vue {
  private config: Config | false = false;
  async created(): Promise<void> {
    this.config = await this.$store.state.config;
    /*eslint-disable */
    // @ts-ignore
    (function (w, d, s, l, i) {w[l] = w[l] || [];w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });var f = d.getElementsByTagName(s)[0],j = d.createElement(s),dl = l != "dataLayer" ? "&l=" + l : "";j.async = true;j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;f.parentNode.insertBefore(j, f);})(window, document, "script", "dataLayer", this.id);
    /*eslint-enable */
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>

import { createStore } from "vuex";

export default createStore({
  state: {
    config: {},
  },
  mutations: {},
  actions: {
    async setConfig(context) {
      const config = await fetch("home.json");
      context.state.config = await config.json();
      return config;
    },
  },
  modules: {},
});

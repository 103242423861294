
import { Options, Vue } from "vue-class-component";
import Config from "@/interfaces/config";

@Options({
  props: {
    msg: String,
  },
})
export default class Banner1 extends Vue {
  private config: Config | false = false;
  private logoImage = "";
  private bannerText = "";
  private bannerDesc = "";
  private bannerWhatsText = "";
  private bannerWhatsLink = "";

  isMobile(): boolean {
    //@todo transformar em função globaç
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
  async created(): Promise<void> {
    console.log("Created Banner1");
    this.config = await this.$store.state.config;
    if (this.config) {
      this.slideStyle.background = this.isMobile()
        ? this.config.header.bannerBackgroundMobile
        : this.config.header.bannerBackground;
      this.logoImage = this.config.geral.logoImage;
      this.bannerText = this.config.header.bannerText;
      this.bannerDesc = this.config.header.bannerDesc;
      this.bannerWhatsText = this.config.header.bannerWhatsText;
      this.bannerWhatsLink = this.config.geral.whatsLink;
      this.logoStyle.width = this.config.header.logoWidth;
      this.logoStyle.height = this.config.header.logoHeight;
    }
  }

  slideStyle = {
    background: "",
  };
  logoStyle = {
    width: "",
    height: "",
  };
}
